import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faGithub,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import ProfilePicture from '../components/ProfilePicture';

library.add(faTwitter, faLinkedin, faGithub);

const BigArea = styled.div`
  text-align: center;
  grid-column-start: 1;
  grid-column-end: span 3;

  grid-row-start: 2;
  grid-row-end: span 3;
  align-self: center;
  justify-self: center;
`;
const TopWrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 10px;
`;

const LinksContainer = styled.div`
  height: 32px;
  max-height: 32px;
`;

interface SocialMediaLink {
  name: string;
  link: string;
  icon: IconDefinition;
}

const socialMediaLinks = [
  {
    name: 'Twitter',
    link: 'https://twitter.com/attehuhtakangas',
    icon: faTwitter,
  },
  {
    name: 'Linkedin',
    link: 'https://www.linkedin.com/in/atte-huhtakangas',
    icon: faLinkedin,
  },
  { name: 'Github', link: 'https://github.com/jazmon', icon: faGithub },
];

const StyledLink = styled.a`
  text-decoration: none;
  margin: auto 0.5rem;
  height: 32px;
  width: 32px;

  > svg {
    width: 32px !important;
    height: 32px !important;
  }
`;

const SocialLink = ({ name, link, icon }: SocialMediaLink) => (
  <StyledLink
    href={link}
    rel="noopener noreferrer"
    target="_blank"
    aria-label={name}
  >
    <FontAwesomeIcon icon={icon} size="2x" color="#171717" />
    {/* {name} */}
  </StyledLink>
);

const IndexPage = () => (
  <Layout>
    <TopWrapper>
      <BigArea>
        <div>
          <ProfilePicture />
          <h1>Atte Huhtakangas</h1>
          <p>An artisanal software craftsman of gluten free software.</p>
        </div>
        <br />
        <LinksContainer>
          {socialMediaLinks.map(socialLink => (
            <SocialLink key={socialLink.name} {...socialLink} />
          ))}
        </LinksContainer>
      </BigArea>
    </TopWrapper>
  </Layout>
);

export default IndexPage;
