import 'modern-normalize/modern-normalize.css';
import * as React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;
  max-height: 100vh;
  max-width: 960px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0;
`;

interface LayoutProps {
  children: React.ReactChild;
}

const Layout = ({ children }: LayoutProps) => (
  <div>
    <Helmet
      title="Atte Huhtakangas's Home page"
      meta={[
        {
          name: 'description',
          content: 'A home page of an artisanal craftsman of code',
        },
        {
          name: 'keywords',
          content:
            'developer, programmer, artesan, craftsman, react, react-native, nodejs, node, coding, programming',
        },
      ]}
    />
    <Container>{children}</Container>
  </div>
);

export default Layout;
