import * as React from 'react';
import styled from 'styled-components';
import * as profilePicture from '../assets/images/atte.jpg';

const size = '4rem';

const ProfilePictureImage = styled.img`
  width: ${size};
  height: ${size};
  border-radius: 100%;
  box-shadow: 0px 0px 3px 0px hsla(0, 0%, 15%, 0.6);
  display: inline-block;
`;

const ProfilePicture = () => (
  <ProfilePictureImage src={profilePicture} aria-hidden />
);

export default ProfilePicture;
